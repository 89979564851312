import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Libraries]
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import SwiperCore, { Pagination } from 'swiper'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'

SwiperCore.use([Pagination])

interface HighlightedProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Highlighted: React.FC<HighlightedProps> = ({ fields }) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedQueryQuery>(graphql`
    query highlightedQuery {
      allWpPost(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges
  const postIds = fields.posts?.map((post) => post?.databaseId)

  return (
    <section>
      <BlogBink
        showIds={postIds as Array<number>}
        posts={posts as BlogBinkPosts}
        id="highlighted"
        limit={Infinity}
      >
        <div className="py-5">
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

const SwiperContainer = styled.div<{ marginLeft: number }>`
  max-width: initial !important;
  position: relative;
  width: calc(100% - ${({ marginLeft }) => marginLeft}px + 0.75rem) !important;
  margin-left: ${({ marginLeft }) => marginLeft}px !important;

  & .swiper {
    padding-right: 1.5rem;
  }
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  const [marginLeft, setMarginLeft] = React.useState<number | null>(null)
  const containerRef = React.useRef(null)

  const handleResize = () => {
    if (!containerRef.current) {
      return
    }

    const nodeStyle = window.getComputedStyle(containerRef.current)
    const nodeMarginRight = nodeStyle.getPropertyValue('margin-left')

    setMarginLeft(Number(nodeMarginRight.replace('px', '')))
  }

  React.useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', () => handleResize)
    }
  }, [])

  return (
    <>
      <div className="container" ref={containerRef}>
        <div className="row d-lg-flex d-none">
          {blogBink.posts.map((post) => {
            const { node }: any = post

            return (
              <div key={post.node.id} className="col-lg-4 mt-5">
                <BlogGridPost node={node} blogFields={fields} />
              </div>
            )
          })}
        </div>
      </div>

      {marginLeft !== null && (
        <SwiperContainer
          className="container d-lg-none"
          marginLeft={marginLeft}
        >
          <Swiper
            breakpoints={{
              768: {
                slidesPerView: 2.25,
                spaceBetween: 30,
              },
              450: {
                slidesPerView: 1.75,
                spaceBetween: 20,
              },
              0: {
                slidesPerView: 1.25,
                spaceBetween: 10,
              },
            }}
          >
            {blogBink.posts.map((post) => {
              const { node }: any = post

              return (
                <SwiperSlide key={post.node.id}>
                  <BlogGridPost node={node} blogFields={fields} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </SwiperContainer>
      )}
    </>
  )
}

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: {
    id: string
    title: string
    uri: string
    recap: {
      excerpt: string
    }
  }
}

const StyledBlogGridPost = styled.div`
  background-color: pink;
`

const BlogGridPostExcerpt = styled(ParseContent)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 75px;
  max-width: 100%;
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => (
  <StyledBlogGridPost>
    <h2>{node.title}</h2>
    <BlogGridPostExcerpt content={node.recap.excerpt} />
    <div className="mt-2">
      <ButtonPrimary to={node.uri}>{blogFields.readmoretext}</ButtonPrimary>
    </div>
  </StyledBlogGridPost>
)

export default Highlighted
